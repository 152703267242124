import React from "react";
import Layout from "../layout";
import styled from "styled-components";
import SEO from "../components/seo";
import AnchorLink from "react-anchor-link-smooth-scroll";
// import bgImg from "../images/404-bg.png";
import {
  darkBlue,
  mainWhite,
  Subtitle,
  InnerWrapper,
  screen,
} from "../components/common/variables";

const Wrapper = styled.div`
  min-height: 70vh;
  background-color: ${mainWhite};
  padding-top: 30px;
  @media ${screen.medium} {
    padding-top: 160px;
  }

  p {
    color: ${darkBlue};
    margin-top: 30px;
    font-size: 1.1rem;
    max-width: 880px;
    @media ${screen.small} {
      font-size: 1.1rem;
    }
    @media ${screen.large} {
      font-size: 1.2rem;
    }
    @media ${screen.xLarge} {
      font-size: 1.3rem;
    }
    @media ${screen.xXLarge} {
      font-size: 1.4rem;
    }

    a {
      color: ${darkBlue};
      font-weight: 700;
    }
  }
`;

const RegistrationConfirmationPage = () => {
  return (
    <Layout>
      <SEO title="Email Confirmed! - Global Adviser Alpha" />
      <Wrapper>
        <div>
          <InnerWrapper className="inner-wrapper">
            <Subtitle className="title" dark>
              Thank you
            </Subtitle>
            <p>
              Thanks for registering your interest in our facilitated Client
              Centric Advice Program. <br />
              We are excited to have you join us and to starting helping you to
              improve your business outcomes for all stakeholders as soon as
              possible.
            </p>
            <p>
              As you know we need a minimum of 12 participants to make a start.
              If you have any friends or colleagues that may benefit from this
              program, be sure to get them to register.
              <br />
              We’ll be back to you as soon as we have a quorum. We are super
              excited to make a start with you as soon as possible.
            </p>
          </InnerWrapper>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default RegistrationConfirmationPage;
